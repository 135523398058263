import { UpdateContactForm } from "../components/UpdateContactForm"
import { UpdateAssetForm } from "../components/UpdateAssetForm"
import { InsertForm } from "../components/InsertForm"
import { Stack } from "@mui/material"
import axios from "axios"

export const ManagerView = () => {
    return (
        <Stack direction='row' justifyContent={'center'} gap={20} >
            <UpdateContactForm onSave={async (values: any) => {
                await axios.patch(`http://77.126.117.14:5000/contact/${values.oldPhoneNum}`, (({ oldPhoneNum, ...object }) => object)(values))
            }} />
            <InsertForm onSave={async (values: any) => {
                await axios.post(`http://77.126.117.14:5000/insert`, values)
            }} />
            <UpdateAssetForm onSave={async (values: any) => {
                await axios.patch(`http://77.126.117.14:5000/asset/${values.externalId}`, values)
            }} />
        </Stack>
    )
}