export const transactionTypeOptions = ['מכירה', 'השכרה']
export const typeOptions = [
    'אחר',
    'בית פרטי',
    'דירה',
    'דירת גן',
    'חנות',
    'חנייה',
    'יחידת דיור',
    'מגרש',
    'מחסן',
    'מיני פנטהאוז',
    'מרתף',
    'משרד',
    'עסק',
    'פנטהאוז',
    'קוטז',
    'קראוון'
]
export const roomNumOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const locationOptions = [
    'אום אל-פחם',
    'אופקים',
    'אור יהודה',
    'אור עקיבא',
    'אילת',
    'אלעד',
    'אריאל',
    'אשדוד',
    'אשקלון',
    'באקה אל-גרבייה',
    'באר יעקב',
    'באר שבע',
    'בית שאן',
    'בית שמש',
    'ביתר עילית',
    'בני ברק',
    'בת ים',
    'גבעת שמואל',
    'גבעתיים',
    'דימונה',
    'הוד השרון',
    'הרצליה',
    'חדרה',
    'חולון',
    'חיפה',
    'חריש',
    'טבריה',
    'טייבה',
    'טירה',
    'טירת כרמל',
    'טמרה',
    'יבנה',
    'יהוד-מונוסון',
    'יקנעם עילית',
    'ירושלים',
    'ישובים',
    'ישובים - אבן יהודה',
    'ישובים - אלישיב',
    'ישובים - בורגתה',
    'ישובים - בני דרור',
    'ישובים - בצרה',
    'ישובים - בת חן',
    'ישובים - גאולים',
    'ישובים - חניאל',
    'ישובים - חרות',
    'ישובים - ינוב',
    'ישובים - כפר הס',
    'ישובים - כפר נטר',
    'ישובים - מונש',
    'ישובים - משמרות',
    'ישובים - ניצני עוז',
    'ישובים - עולש',
    'ישובים - עין ורד',
    'ישובים - עין שריד',
    'ישובים - עמק חפר',
    'ישובים - פורת',
    'ישובים - פרדסיה',
    'ישובים - צור משה',
    'ישובים - צורן',
    'ישובים - קדימה',
    'ישובים - רשפון',
    'ישובים - שער אפרים',
    'ישובים - תל מונד',
    'ישובים - תנובות',
    'כפר יונה',
    'כפר יונה - גבעת אלונים',
    'כפר יונה - יפה נוף',
    'כפר יונה - שרונה',
    'כפר סבא',
    'כפר קאסם',
    'כרמיאל',
    'לוד',
    'מגדל העמק',
    'מודיעין עילית',
    'מודיעין- מכבים- רעות',
    'מעלה אדומים',
    'מעלות- תרשיחא',
    'נהריה',
    'נוף הגליל',
    'נס ציונה',
    'נצרת',
    'נשר',
    'נתיבות',
    'נתניה',
    'נתניה - אגמים',
    'נתניה - ותיקים',
    'נתניה - מרכז העיר',
    'נתניה - עיר ימים',
    'נתניה - פולג',
    'נתניה - קריית השרון',
    "סח'נין",
    'עכו',
    'עפולה',
    'עראבה',
    'ערד',
    'פתח תקווה',
    'צפת',
    'קלנסווה',
    'קריית אונו',
    'קריית אתא',
    'קריית ביאליק',
    'קריית גת',
    'קריית ים',
    'קריית מוצקין',
    'קריית מלאכי',
    'קריית שמונה',
    'ראש העין',
    'ראשון לציון',
    'רהט',
    'רחובות',
    'רמלה',
    'רמת גן',
    'רמת השרון',
    'רעננה',
    'שדרות',
    'שפרעם',
    'תל אביב-יפו'
]

export const MaxSearchNum = 10
